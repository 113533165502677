

const PagesAccess = {
    getPageRemoveAccess(email) {
        let pageRemove = []
        if(email){
            let domain = email.split('@')[1]      
            if(domain == "esshva.com"){
                pageRemove.push('CandidateDatabase')
            }
        }     
        return pageRemove;
    }
}

export default PagesAccess
