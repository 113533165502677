import axios from "axios";
let API_URL = process.env.VUE_APP_AXPARA_API;
const state = {
    auth: {
        isLogin: false,
        redirect: ''
    },
    user:{},
    userLocation:localStorage.getItem('location'),
    count: 0,
    interviewData: {},
    isLoginPopUp: false
};


const getters = {
    isAuthenticated: state => state.auth.isLogin,
    StateUser: state => state.user,
    getUserLocation: state => state.userLocation
}
const mutations = {
    SET_LOGIN(state, data) {
        state.auth.isLogin = data;
    },
    SET_USER(state, data) {
        state.user = data;
    },
    SET_REDIRECT(state, data) {
        state.auth.redirect = data;
    },
    SET_INTERVIEW_DATA(state, data) {
        state.interviewData = data;
    },
    SET_LOGIN_POPUP(state, data) {
        state.isLoginPopUp = data;
    }
}
const actions = {
    setLogin({ state, commit, rootState }, data) {
        commit("SET_LOGIN", data);
    },
    setRedirect({ state, commit, rootState }, data) {
        commit("SET_REDIRECT", data);
    },
    setLoginPopUp({ state, commit, rootState }, data) {
        commit("SET_LOGIN_POPUP", data);
    },
    setInterviewData({ state, commit, rootState }, data) {
        commit("SET_INTERVIEW_DATA", data);
    },
    async setUser({state, dispatch}, credentials) {       
        try{
            let getUser = await axios.get(API_URL+'client/auth/me');
            dispatch('attemp', getUser.data);
        }catch(e){
            dispatch('errorAttemp', {})
        }    
    },
    async userLogout({dispatch}){
        dispatch('errorAttemp', {});      
    },
    async errorAttemp({ state, commit, rootState }, data){
        commit("SET_USER", data);
        commit("SET_LOGIN", false);
        // commit("SET_REDIRECT", '');
        commit("SET_INTERVIEW_DATA", {});
    },
    async attemp({ state, commit, rootState }, data){
        commit("SET_USER", data);
        commit("SET_LOGIN", true);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
