const Module = () => import("./Module.vue");

const moduleRoute = {
    path: '/support',
    name: 'UserGuide',
    component: Module,
    meta: {access: 'userguide' },
    children: [{
            path: '',
            name: 'UserGuideHome',
            meta: { isTalentAccess: true, access: 'userguide' },
            component: () =>
                import('./views/Index.vue'),
        }
    ]
}
export default router => {
    router.addRoute(moduleRoute);
};