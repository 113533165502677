const Module = () => import("./Module.vue");

const moduleRoute = {
    path: "/landingpage",
    component: Module,
    children: [
      {
        path: '/',
        name: 'LandingPage',
        component: () =>
        import ('./views/LandingPage.vue')
      },
      {
        path: '/cv/:shortUuid',
        name: 'PDFviewerForALL',
        component: () =>
        import ('./views/pdf/PDFViewer.vue')
      },
      {
        path: '/:publicHandle',
        name: 'PDFPublicHandle',
        component: () =>
        import ('./views/pdf/PDFPublicHandle.vue')
      },
      {
        path: '/home',
        name: 'HomePage',
        component: () =>
        import ('./views/LandingPage.vue')
      },
    ]
  };
  
export default router => {
    router.addRoute(moduleRoute);
};