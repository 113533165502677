import { createApp } from 'vue'
import App from './App.vue'
import { store } from "./store";
import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import './assets/css/font.css'
import './assets/css/border.css'
import './assets/css/button.css'
import './assets/css/input.css'
import './assets/css/margin-padding.css'
import './assets/css/table.css'
import './assets/css/cards.css'
import './assets/css/popup.css'
import './assets/css/date.css'
import './assets/css/component/components.css'
import "toastify-js/src/toastify.css"
import "@vueform/slider/themes/default.css"
import './assets/css/component/video.css'
import './assets/css/responsive.css'
import { registerModules } from "./register-modules";
import landingpage from "./modules/landingpage";
import freetier from "./modules/freetier";
import talent from "./modules/talent";
import client from "./modules/client";
import userguide from "./modules/userguide";
import router from './router'
const GOOGLE_ANALYTICS_ID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID;

registerModules({
    landingpage: landingpage,
    freetier: freetier,
    talent: talent,
    client: client,
    userguide: userguide
});
const app = createApp(App)
app.use(VueGtag, {
    config: { id: GOOGLE_ANALYTICS_ID }
})
app.use(router)
app.use(store)
app.use(VueCookies)
app.mount('#app')