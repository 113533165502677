import axios from "axios";
axios.defaults.withCredentials = true;
let API_URL = process.env.VUE_APP_AXPARA_API_TALENT;
const state = {
    auth: {
        isLogin: false,
        redirect: ''
    },
    user: {},
    userLocation: localStorage.getItem('location'),
    count: 0,
    showBrowseJob: 
    {
        date: new Date(),
        isShow: false
    }
};


const getters = {
    isAuthenticated: state => state.auth.isLogin,
    StateUser: state => state.user,
    getUserLocation: state => state.userLocation
}
const mutations = {
    SET_LOGIN(state, data) {
        state.auth.isLogin = data;
    },
    IS_SHOW_BROWSE_JOB(state, data) {
      
        state.showBrowseJob = {
            date: new Date(),
            isShow: data
        };
    },
    SET_USER(state, data) {
        state.user = data;
    },
    SET_REDIRECT(state, data) {
        state.auth.redirect = data;
    }
}
const actions = {
    async clickShowBrowseJob({ state, commit, rootState }, data) {
        commit("IS_SHOW_BROWSE_JOB", data);
    }, 
    setLogin({ state, commit, rootState }, data) {
        commit("SET_LOGIN", data);
    },
    setRedirect({ state, commit, rootState }, data) {
        commit("SET_REDIRECT", data);
    },
    async setUser({ state, dispatch }, credentials) {
        try {
            let getUser = await axios.get(API_URL + 'talent/auth/me');
            dispatch('attemp', getUser.data)

        } catch (e) {
            dispatch('errorAttemp', {})
        }
    },
    async userLogout({ dispatch }) {
        dispatch('errorAttemp', {})
    },
    async errorAttemp({ state, commit, rootState }, data) {
        commit("SET_USER", data);
        commit("SET_LOGIN", false);
    },
    async attemp({ state, commit, rootState }, data) {
        commit("SET_USER", data);
        commit("SET_LOGIN", true);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
