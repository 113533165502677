import axios from "axios";
let API_URL = process.env.VUE_APP_AXPARA_API;
const state = {
    auth: {
        isLogin: false,
        redirect: ''
    },
    user:{},
    userLocation:localStorage.getItem('location'),
    count: 0
};


const getters = {
    isAuthenticated: state => state.auth.isLogin,
    StateUser: state => state.user,
    getUserLocation: state => state.userLocation
}
const mutations = {
    SET_LOGIN(state, data) {
        state.auth.isLogin = data;
    },
    SET_USER(state, data) {
        state.user = data;
    },
    SET_REDIRECT(state, data) {
        state.auth.redirect = data;
    }
}
const actions = {
    setLogin({ state, commit, rootState }, data) {
        commit("SET_LOGIN", data);
    },
    setRedirect({ state, commit, rootState }, data) {
        commit("SET_REDIRECT", data);
    },
    async setUser({state, dispatch}, credentials) {      
        try{           
            let getUser = await axios.get(API_URL+'free-client/auth/me');   
            dispatch('attemp', getUser?.data);
            return true;
        }catch(e){
            dispatch('errorAttemp', {})
            return false;
        }
    },
    async userLogout({dispatch}){
        dispatch('errorAttemp', {})
    },
    async errorAttemp({ state, commit, rootState }, data){
        commit("SET_USER", data);
        commit("SET_LOGIN", false);
    },
    async attemp({ state, commit, rootState }, data){
        commit("SET_USER", data);
        commit("SET_LOGIN", true);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
